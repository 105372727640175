body {
	height: 100vh;
	width: 100vw;
	color: #4b535a;
  font-family: 'lato', sans serif;
}

#root {
	width: 100%;
	height: 100%;
}

.full-size {
  position: relative;
  width: 100vw;
  height: 100vh;
}

.content {
  padding: 40px 110px;
  /* max-width: 1200px; */
}

.text-10 {
	font-size: 10pt;
}

.flex {
	display: flex;
}

.flex-center {
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
}

.align-left {
	text-align: left;
}

.full-size-bg {
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	width: 100%;
	height: 100%;
}

.bg-opening {
	background-image: url('../img/bg-cover.png');
	overflow: hidden;
	/* opacity: .5; */
}

.accordion {
	overflow: hidden;
	max-height: 1000px;
	transition: all .5s ease;
}
.accordion.is-hidden {
	max-height: 0px;
}

.box-overlay {
	pointer-events: none;
	width: 100%;
	height: 100%;
}
.max-height-500 {
	max-height: 500px;
}

.gap-top-60 {
  margin-top: 60px;
}

.box-overlay * {
	pointer-events: initial;
}

.center-box {
	position: relative;
  overflow-x: hidden;
	overflow-y: auto;
  display: flex;
  flex-direction: column;
  height: 90%;
  max-height: 600px;
  width: 650px;
  max-width: 90%;
  border-radius: 3px;
  background-color: white;
  box-shadow: 0 2px 40px rgba(0, 0, 0, 0.18);
  z-index: 100;
	transition: all .5s ease;
	
}

/* .rounded-corners {
	background:
    radial-gradient(circle at 0 100%, rgba(204,0,0,0) 14px, #fff 15px),
    radial-gradient(circle at 100% 100%, rgba(204,0,0,0) 14px, #fff 15px),
    radial-gradient(circle at 100% 0, rgba(204,0,0,0) 14px, #fff 15px),
    radial-gradient(circle at 0 0, rgba(204,0,0,0) 14px, #fff 15px);
  background-position: bottom left, bottom right, top right, top left;
  background-size: 50% 50%;
  background-repeat: no-repeat;
  padding: 14px;
} */
.center-box__content-container {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  min-height: 100%;
  width: 100%;
  transform: translateX(0);
  transition: transform .5s ease;
}

.center-box__content-container.left-out {
  transform: translateX(-100%);
}

.center-box__content-container.right-out {
  transform: translateX(100%);
}

.center-box__content {
	padding: 30px 50px 20px 50px;
	flex: 1 1 auto;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 18pt;
	color: #636363;
  white-space: pre-line;
	/* height: auto; */
}

/* .center-box__content span {
	margin-bottom: 20px;
  display: inline-block;
} */

.center-box__content p {
  margin-bottom: 1.8rem;
}

.center-box__img {
	position: absolute;
  top: 100px;
  width: 410px;
  max-width: 80%;
}

.center-box__btn-area {
  display: flex;
  height: 60px;
  box-shadow: 0 0 21px rgba(0, 0, 0, 0.07);
}

.center-box__btn-area .btn {
  flex: 1 1 100%;
  color:  #31D4AB;
}



.opening-transition-enter {
  opacity: 0.01;
  transform: translateY(80%);
}

.opening-transition-enter.opening-transition-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: all 1.5s cubic-bezier(0.01, 0.46, 0.24, 0.98);
}

.opening-transition-leave {
  opacity: 1;
  transform: translateY(0);
}

.opening-transition-leave.opening-transition-leave-active {
  opacity: 0.01;
  transform: translateY(80%);
  transition: all 600ms ease-in;
}

.center-box__outer-right {
	position: absolute;
	display: flex;
  align-items: center;
  right: 0;
  /* background: red; */
  height: 100%;
  top: 0;
  transform: translateX(100%);
}

.center-box__inner-right {
	position: absolute;
	right: 15px;
	bottom: 0;
}

.btn-progress {
  margin-left: 30px;
  font-weight: lighter;
  color: #31d4ab;
  font-size: 32pt;
  text-transform: uppercase;
}

.btn:hover {
	color: black;
}



.arrow-right {
	margin-left: 10px;
	transition: transform .5s ease;
}

.btn:hover .arrow-right {
  transform: translateX(20px);
	
}

/* Confugurator */

.configurator-button {
	position: relative;
	width: 100%;
  background-color: #31d4ab;
  border-radius: 0;
  height: 90px;
  flex: 1 0 90px;
  color: rgba(255, 255, 255, 0.85);
  text-transform: uppercase;
  box-shadow: 0 4px 21px rgba(0, 0, 0, 0.11);
}
.configurator-button:hover {
	color:  white;
}
.configurator__slider {
	padding: 20px;
  max-width: 480px;
  margin: 0 auto;
}

.configurator__rating {
  display: flex;
  justify-content: space-between;
  font-size: 8pt;
  text-transform: uppercase;
  margin-bottom: -8px;
}

.configurator__title {
	text-align: left;
  margin-bottom: 11px;
}

.configurator__container {
	position: relative;
	min-height: 100%;
  /* height: 100%; */
  display: flex;
  flex-direction: column;
  /* margin: 0 auto -90px; */
}

/*                 SLIDER                 */
/**
* Rangeslider
*/

.slider {
	width: 100%;
}
.rangeslider {
  margin: 20px 0;
  position: relative;
  background: #e6e6e6;
  -ms-touch-action: none;
  touch-action: none;
}
.rangeslider,
.rangeslider .rangeslider__fill {
  display: block;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.4);
}
.rangeslider .rangeslider__handle {
  background: #fff;
  border: 1px solid #ccc;
  cursor: pointer;
  display: inline-block;
  position: absolute;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4), 0 -1px 3px rgba(0, 0, 0, 0.4);
}
.rangeslider .rangeslider__handle .rangeslider__active {
  opacity: 1;
}
.rangeslider .rangeslider__handle-tooltip {
	display: none;
  width: 40px;
  height: 40px;
  text-align: center;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.8);
  font-weight: normal;
  font-size: 14px;
  transition: all 100ms ease-in;
  border-radius: 4px;
  /* display: inline-block; */
  color: white;
  left: 50%;
  transform: translate3d(-50%, 0, 0);
}
.rangeslider .rangeslider__handle-tooltip span {
  margin-top: 12px;
  display: inline-block;
  line-height: 100%;
}
.rangeslider .rangeslider__handle-tooltip:after {
  content: ' ';
  position: absolute;
  width: 0;
  height: 0;
}
/**
* Rangeslider - Horizontal slider
*/
.rangeslider-horizontal {
  height: 12px;
  border-radius: 10px;
}
.rangeslider-horizontal .rangeslider__fill {
  height: 100%;
  background-color: #31D4AB;
  border-radius: 10px;
  top: 0;
}
.rangeslider-horizontal .rangeslider__handle {
  width: 30px;
  height: 30px;
  border-radius: 30px;
  top: 50%;
  transform: translate3d(-50%, -50%, 0);
}
/* .rangeslider-horizontal .rangeslider__handle:after {
  content: ' ';
  position: absolute;
  width: 16px;
  height: 16px;
  top: 6px;
  left: 6px;
  border-radius: 50%;
  background-color: #dadada;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4) inset, 0 -1px 3px rgba(0, 0, 0, 0.4) inset;
} */
.rangeslider-horizontal .rangeslider__handle-tooltip {
  top: -55px;
}
.rangeslider-horizontal .rangeslider__handle-tooltip:after {
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid rgba(0, 0, 0, 0.8);
  left: 50%;
  bottom: -8px;
  transform: translate3d(-50%, 0, 0);
}
/**
* Rangeslider - Vertical slider
*/
.rangeslider-vertical {
  margin: 20px auto;
  height: 150px;
  max-width: 10px;
  background-color: transparent;
}
.rangeslider-vertical .rangeslider__fill,
.rangeslider-vertical .rangeslider__handle {
  position: absolute;
}
.rangeslider-vertical .rangeslider__fill {
  width: 100%;
  background-color: #7cb342;
  box-shadow: none;
  bottom: 0;
}
.rangeslider-vertical .rangeslider__handle {
  width: 30px;
  height: 10px;
  left: -10px;
  box-shadow: none;
}
.rangeslider-vertical .rangeslider__handle-tooltip {
  left: -100%;
  top: 50%;
  transform: translate3d(-50%, -50%, 0);
}
.rangeslider-vertical .rangeslider__handle-tooltip:after {
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-left: 8px solid rgba(0, 0, 0, 0.8);
  left: 100%;
  top: 12px;
}
/**
* Rangeslider - Reverse
*/
.rangeslider-reverse.rangeslider-horizontal .rangeslider__fill {
  right: 0;
}
.rangeslider-reverse.rangeslider-vertical .rangeslider__fill {
  top: 0;
  bottom: inherit;
}
/**
* Rangeslider - Labels
*/
.rangeslider__labels {
  position: relative;
}
.rangeslider-vertical .rangeslider__labels {
  position: relative;
  list-style-type: none;
  margin: 0 0 0 24px;
  padding: 0;
  text-align: left;
  width: 250px;
  height: 100%;
  left: 10px;
}
.rangeslider-vertical .rangeslider__labels .rangeslider__label-item {
  position: absolute;
  transform: translate3d(0, -50%, 0);
}
.rangeslider-vertical .rangeslider__labels .rangeslider__label-item::before {
  content: '';
  width: 10px;
  height: 2px;
  background: black;
  position: absolute;
  left: -14px;
  top: 50%;
  transform: translateY(-50%);
  z-index: -1;
}
.rangeslider__labels .rangeslider__label-item {
  position: absolute;
  font-size: 14px;
  cursor: pointer;
  display: inline-block;
  top: 10px;
  transform: translate3d(-50%, 0, 0);
}


/*               ANIMATION               */
.message {
  height: 100%;
}
.message-enter {
  opacity: 0.3;
  transform: translateX(100%);
}
.message--back-enter {
  opacity: 0.3;
  transform: translateX(-100%);
}
.message-enter-active,
.message--back-enter-active {
  opacity: 1;
  transform: translateX(0%);
  transition: all .8s ease;
}
.message-exit,
.message--back-exit {
  opacity: 1;
  transform: scale(1) translateX(0%);
}
.message-exit-active,
.message--back-exit-active {
  opacity: 0.3;
  transition: all .8s ease;
}

.message-exit-active {
  transform: translateX(-100%);
}

.message--back-exit-active {
  transform: translateX(100%);
}

.message-enter,
.message-exit,
.message-enter-done,
.message--back-enter,
.message--back-exit,
.message--back-enter-done {
  min-height: 100%;
  /* display: flex;
  flex-direction: column; */
}

.noSelect {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    /* -webkit-tap-highlight-color: transparent; */
    outline:none;
    /* -webkit-tap-highlight-color: rgba(255, 255, 255, 0); */
    -webkit-tap-highlight-color: transparent;
}



/*                NEWSLETTER                */

.newsletter__input {
  height: 60px;
}

.newsletter input {
  background-color: whitesmoke;
  outline: none;
  box-shadow: none;
  border: none;
  padding: 0 25px;
  height: 100%;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
}

.newsletter__submit {
  height: 100%;
  background-color: #31D4AB;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
  min-width: 60px;
  /* color: white; */
  padding: 0 19px;
  font-size: 16pt;
}

.img-monster {
  width: 200px;
  margin:  40px;
}

.btn-outline {
  color: #31D4AB;
}

.btn.green {
  background-color: #31D4AB;
  color: white;
}

.btn.green:hover {
  color:  white;
}

.btn.btn-outline:hover {
  color: #31D4AB;
}

.back-button {
  position: absolute;
  top: 0;
  left: 0;
  padding: 10px;
  color: lightgray;
  z-index: 10;
}

.team-container {
  align-items: flex-start;
  width: 100%;
}

.team {
  flex: 1 1 25%;
}

.team-name {
  font-size: 12pt;
  margin-top: 8px;
  margin-bottom: 6px;
}

.team-title {
  font-size: 11pt;
  color: #31D4AB;
  line-height: 1em;
  display: flex;
  min-height: 30px;
  align-items: center;
  justify-content: center;
}

.team-img {
  width: 100px;
  height: 100px;
  overflow: hidden;
  border-radius: 50%;
}


/*            SEARCH              */

.product {
  flex: 0 1 20%;
  padding: 10px;
}

.product__box {
  position: relative;
  overflow: hidden;
  box-shadow: 0 2px 24px rgba(0, 0, 0, 0.11);
  border-radius: 3px;
  padding: 20px;
  min-height: 200px;
}

.product__rating-line {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  color: white;
  height: 2px;
  display: flex;
  background-color: lightgray;
  justify-content: center;
  align-items: center;
  transition: height .3s ease;
}

.product__box span {
  transform: translateY(100%);
  transition: transform .3s ease;
}

.product__box:hover .product__rating-line {
  height: 30px;
}

.product__box:hover span {
  transform: translateY(0);
}

.product__img {
  max-height: 120px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.product__img img {
  max-width: 100%;
}




/*                   CATEGORY                   */

.category-slide {
  position: relative;
  list-style: none;
  cursor: pointer;
  width: 190px;
  color: #b1b1b1;
  background-color: #fff;
  margin-bottom: 8px;
  padding: 6px 30px 6px 15px;
  border-right: 4px solid #d3d3d3;
  transform: translateX(-10px);
  box-shadow: 0 1px 1.5px rgba(0,0,0,.12), 0 1px 1px rgba(0,0,0,.24);
  transition: all .3s ease;
}

.category-slide:not(.active):hover {
  color:  gray;
  transform: translateX(0);
}

.category-slide.active {
  cursor: auto;
  color: initial;
  box-shadow: none;
  font-weight: bold;
  border-right: none;
}

.category-slide.parent {
  box-shadow: none;
  border-right: none;
}

.category-slide.parent:before {
  content:  '<';
}

@media (max-width: 600px) {
	.center-box__content {
		font-size: 16pt;
    font-weight: 200;
    padding: 30px 30px 20px 30px;
	}
	.btn-progress {
		font-size: 26pt;
	}
  .newsletter__input {
    height: 50px;
  }
  .newsletter input {
    font-size:  10pt;
  }
  .newsletter__submit {
    padding: 0 15px;
  }
}

